import { CurrencyCode } from './currency';
import { Payout } from './payout';
import { IReportPreview } from './performance';
import { ISecret, ISecretConfigField } from './secret';
import { Timestamp } from './time';

export const CLOUD_STORAGE_BUCKET = 'reports';
export const SECRET_CATEGORY = 'REPORTING_API_SECRET';

export const HANDLERS_WITH_RATES = [
  'impactV1',
  'skimlinksV1',
  'amazonV2',
  'avantlinkV1',
  'awinV1',
  'cjV1',
  'pepperjamV1',
  'shareasaleV1',
  'partnerizeV1',
  'rakutenV2',
  'refersionV1',
  'levantaV1',
  'howlV1',
  'sovrnV1'
];

export const HANDLERS_WITH_PAYOUTS = [
  'skimlinksV1',
  'impactV1',
  'shareasaleV1',
  'postAffiliateProV1',
  'partnerizeV1',
  'rakutenV2',
  'pepperjamV1',
  'redVenturesV1',
  'refersionV1',
  'tuneV1'
];

export type SecretConfigs = {
  admitadV1: ISecretConfigField[];
  adsByMoneyV1: ISecretConfigField[];
  affiseV1: ISecretConfigField[];
  affilaeV1: ISecretConfigField[];
  affiliatlyV1: ISecretConfigField[];
  affiliateWpV1: ISecretConfigField[];
  amazonFeedV1: ISecretConfigField[];
  amazonV2: ISecretConfigField[];
  avantlinkV1: ISecretConfigField[];
  awinV1: ISecretConfigField[];
  belboonV1: ISecretConfigField[];
  bluehostV1: ISecretConfigField[];
  cakeV1: ISecretConfigField[];
  cannaffiliateV1: ISecretConfigField[];
  cbdAffsV1: ISecretConfigField[];
  civitatisV1: ISecretConfigField[];
  cjV1: ISecretConfigField[];
  clickbankV1: ISecretConfigField[];
  commissionFactoryV1: ISecretConfigField[];
  daisyconV1: ISecretConfigField[];
  digidipV1: ISecretConfigField[];
  ebayV1: ISecretConfigField[];
  effiliationV1: ISecretConfigField[];
  everflowV1: ISecretConfigField[];
  expressVpnV1: ISecretConfigField[];
  financeAdsV1: ISecretConfigField[];
  firstpromoterV1: ISecretConfigField[];
  flexoffersV1: ISecretConfigField[];
  getYourGuideV1: ISecretConfigField[];
  goAffProV1: ISecretConfigField[];
  gbrV1: ISecretConfigField[];
  engineV1: ISecretConfigField[];
  engineV2: ISecretConfigField[];
  freshReachV1: ISecretConfigField[];
  howlV1: ISecretConfigField[];
  idevaffiliateV1: ISecretConfigField[];
  impactV1: ISecretConfigField[];
  jvzooV1: ISecretConfigField[];
  kutokuV1: ISecretConfigField[];
  levantaV1: ISecretConfigField[];
  linkconnectorV1: ISecretConfigField[];
  leaddynoV1: ISecretConfigField[];
  ltkV1: ISecretConfigField[];
  maxbountyV1: ISecretConfigField[];
  mvfV1: ISecretConfigField[];
  odysseyV1: ISecretConfigField[];
  optimiseMediaV1: ISecretConfigField[];
  partnerizeV1: ISecretConfigField[];
  partnerstackV1: ISecretConfigField[];
  pepperjamV1: ISecretConfigField[];
  postAffiliateProV1: ISecretConfigField[];
  rakutenV1: ISecretConfigField[];
  rakutenV2: ISecretConfigField[];
  redVenturesV1: ISecretConfigField[];
  refersionV1: ISecretConfigField[];
  revoffersV1: ISecretConfigField[];
  shareasaleV1: ISecretConfigField[];
  skimlinksV1: ISecretConfigField[];
  stay22V1: ISecretConfigField[];
  sovrnV1: ISecretConfigField[];
  stackedBrandsV1: ISecretConfigField[];
  tradedoublerV1: ISecretConfigField[];
  tradetrackerV1: ISecretConfigField[];
  travelPayoutsV1: ISecretConfigField[];
  tuneV1: ISecretConfigField[];
  voyaginV1: ISecretConfigField[];
  webgainsV1: ISecretConfigField[];
};

export const HANDLER_CONFIGS: SecretConfigs = {
  admitadV1: [
    {
      key: 'clientId',
      label: 'Client ID',
      secret: true,
      type: 'password'
    },
    {
      key: 'clientSecret',
      label: 'Client Secret',
      secret: true,
      type: 'password'
    }
  ],
  adsByMoneyV1: [
    {
      key: 'apiToken',
      label: 'API Token',
      secret: true,
      type: 'password'
    }
  ],
  affiseV1: [
    {
      key: 'advertiser',
      label: 'Advertiser name',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiDomain',
      label: 'API Domain',
      secret: false,
      type: 'url'
    },
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    },
    {
      key: 'trackingDomain',
      label: 'Tracking domain URL',
      secret: false,
      type: 'url'
    }
  ],
  affilaeV1: [
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  affiliatlyV1: [
    {
      key: 'advertiser',
      label: 'Affiliate Program Name',
      secret: false,
      type: 'text'
    },
    {
      key: 'url',
      label: 'Affiliate Login URL',
      secret: false,
      type: 'text'
    },
    {
      key: 'username',
      label: 'Username',
      secret: true,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    }
  ],
  affiliateWpV1: [
    {
      key: 'advertiser',
      label: 'Affiliate Program Name',
      secret: false,
      type: 'text'
    },
    {
      key: 'url',
      label: 'Affiliate Area Login URL (ends in /affiliate-area/)',
      secret: false,
      type: 'text'
    },
    {
      key: 'username',
      label: 'Username',
      secret: true,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    },
    {
      key: 'currency',
      label: 'Commission currency',
      secret: false,
      type: 'currency'
    }
  ],
  amazonV2: [
    {
      key: 'storeId',
      label: 'Store ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'marketplace',
      label: 'Amazon Marketplace',
      secret: false,
      type: 'amazonMarketplace'
    },
    {
      key: 'currency',
      label: 'Report Currency',
      secret: false,
      type: 'currency'
    },
    {
      key: 'trackingIds',
      label: 'Tracking IDs per Property',
      secret: false,
      type: 'propertyAndIdPairs'
    }
  ],
  amazonFeedV1: [
    {
      key: 'username',
      label: 'S3 Feed Username',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'S3 Feed Password',
      secret: true,
      type: 'password'
    },
    {
      key: 'feedUrl',
      label: 'Data Feed URL',
      secret: false,
      type: 'amazonFeedUrl'
    },
    {
      key: 'currency',
      label: 'Report Currency',
      secret: false,
      type: 'currency'
    },
    {
      key: 'storeId',
      label: 'Store ID',
      secret: false,
      type: 'text'
    }
  ],
  cakeV1: [
    {
      key: 'advertiser',
      label: 'Network or Affiliate Program Name',
      secret: false,
      type: 'text'
    },
    {
      key: 'url',
      label: 'Affiliate Login URL',
      secret: false,
      type: 'text'
    },
    {
      key: 'affiliateId',
      label: 'Affiliate ID',
      secret: true,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  cannaffiliateV1: [
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  cbdAffsV1: [
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  civitatisV1: [
    {
      key: 'affiliateId',
      label: 'Affiliate ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'username',
      label: 'Username',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    }
  ],
  commissionFactoryV1: [
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  daisyconV1: [
    {
      key: 'username',
      label: 'Username',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    },
    {
      key: 'publisherId',
      label: 'Publisher ID',
      secret: false,
      type: 'text'
    }
  ],
  financeAdsV1: [
    {
      key: 'userId',
      label: 'User ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    },
    {
      key: 'websiteId',
      label: 'Website ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'domain',
      label: 'Domain name',
      secret: false,
      type: 'domain'
    },
    {
      key: 'currency',
      label: 'Account currency',
      secret: false,
      type: 'currency'
    }
  ],
  firstpromoterV1: [
    {
      key: 'advertiser',
      label: 'Affiliate Program Name',
      secret: false,
      type: 'text'
    },
    {
      key: 'url',
      label: 'Affiliate Login URL',
      secret: false,
      type: 'text'
    },
    {
      key: 'username',
      label: 'Username',
      secret: true,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    }
  ],
  partnerstackV1: [
    {
      key: 'apiKey',
      label: 'User API Key',
      secret: true,
      type: 'password'
    }
  ],
  optimiseMediaV1: [
    {
      key: 'apiKey',
      label: 'User API Key',
      secret: true,
      type: 'password'
    }
  ],
  odysseyV1: [
    {
      key: 'publisherId',
      label: 'Publisher ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'advertiserId',
      label: 'Advertiser ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  partnerizeV1: [
    {
      key: 'userId',
      label: 'Publisher ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'applicationKey',
      label: 'User Application Key',
      secret: true,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'User API Key',
      secret: true,
      type: 'text'
    }
  ],
  clickbankV1: [
    {
      key: 'developerKey',
      label: 'Developer Key',
      secret: true,
      type: 'text'
    },
    {
      key: 'clerkKey',
      label: 'Clerk Key',
      secret: true,
      type: 'text'
    }
  ],
  voyaginV1: [
    {
      key: 'ordersUrl',
      label: 'Your affiliate orders URL',
      secret: false,
      type: 'text'
    }
  ],
  rakutenV2: [
    {
      key: 'clientId',
      label: 'Client ID',
      secret: true,
      type: 'password'
    },
    {
      key: 'clientSecret',
      label: 'Client Secret',
      secret: true,
      type: 'password'
    },
    {
      key: 'marketingChannels',
      label: 'Marketing Channels',
      secret: false,
      type: 'domainAndIdPairs'
    },
    {
      key: 'securityToken',
      label: 'Security Token',
      secret: true,
      type: 'password'
    }
  ],
  stay22V1: [
    {
      key: 'affiliateId',
      label: 'Affiliate ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'API key',
      secret: true,
      type: 'password'
    }
  ],
  rakutenV1: [
    {
      key: 'apiToken',
      label: 'API token',
      secret: true,
      type: 'text'
    },
    {
      key: 'siteId',
      label: 'Site Id',
      secret: false,
      type: 'text'
    },
    {
      key: 'marketingChannel',
      label: 'Domain name',
      secret: false,
      type: 'domain'
    },
    {
      key: 'networkId',
      label: 'Network',
      secret: false,
      type: 'rakutenNetwork'
    }
  ],
  digidipV1: [
    {
      key: 'apiUsername',
      label: 'API Username (Email)',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiPassword',
      label: 'API Password',
      secret: true,
      type: 'password'
    },
    {
      key: 'username',
      label: 'Username (Email)',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    },
    {
      key: 'subdomains',
      label: 'Tracking subdomains',
      secret: false,
      type: 'domainAndIdPairs'
    }
  ],
  travelPayoutsV1: [
    {
      key: 'partnerId',
      label: 'Partner ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiToken',
      label: 'API token',
      secret: true,
      type: 'text'
    },
    {
      key: 'currency',
      label: 'Currency',
      secret: false,
      type: 'travelPayoutsCurrency'
    },
    {
      key: 'projects',
      label: 'Projects',
      secret: false,
      type: 'domainAndIdPairs'
    }
  ],
  getYourGuideV1: [
    {
      key: 'username',
      label: 'Username',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    },
    {
      key: 'currency',
      label: 'Account currency',
      secret: false,
      type: 'currency'
    }
  ],
  goAffProV1: [
    {
      key: 'username',
      label: 'Username',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    }
  ],
  howlV1: [
    {
      key: 'propertyId',
      label: 'Primary Property ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    },
    {
      key: 'slugs',
      label: 'Slugs per domain',
      secret: false,
      type: 'domainAndIdPairs'
    },
    {
      key: 'sites',
      label: 'Publication names per domain',
      secret: false,
      type: 'domainAndIdPairs'
    }
  ],
  engineV1: [
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  engineV2: [
    {
      key: 'domain',
      label: 'Domain',
      secret: false,
      type: 'domain'
    }
  ],
  gbrV1: [
    {
      key: 'domain',
      label: 'Domain',
      secret: false,
      type: 'domain'
    }
  ],
  freshReachV1: [
    {
      key: 'publisherId',
      label: 'Publisher ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'secret',
      label: 'Secret',
      secret: true,
      type: 'password'
    }
  ],
  kutokuV1: [
    {
      key: 'username',
      label: 'Username',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    }
  ],
  levantaV1: [
    {
      key: 'apiToken',
      label: 'API Token',
      secret: true,
      type: 'password'
    }
  ],
  jvzooV1: [
    {
      key: 'affiliateId',
      label: 'Affiliate ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'text'
    }
  ],
  belboonV1: [
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    },
    {
      key: 'adSpaces',
      label: 'Adspaces',
      secret: false,
      type: 'domainAndIdPairs'
    }
  ],
  bluehostV1: [
    {
      key: 'accountUsername',
      label: 'Account Uername',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  sovrnV1: [
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'text'
    },
    {
      key: 'secretKey',
      label: 'Secret Key',
      secret: true,
      type: 'text'
    }
  ],
  tuneV1: [
    {
      key: 'advertiser',
      label: 'Affiliate Program Name',
      secret: false,
      type: 'text'
    },
    {
      key: 'networkId',
      label: 'Network ID',
      secret: true,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  tradedoublerV1: [
    {
      key: 'clientId',
      label: 'Client ID',
      secret: true,
      type: 'text'
    },
    {
      key: 'clientSecret',
      label: 'Client Secret',
      secret: true,
      type: 'text'
    },
    {
      key: 'username',
      label: 'Read-only username',
      secret: true,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Read-only password',
      secret: true,
      type: 'password'
    },
    {
      key: 'currency',
      label: 'Account currency',
      secret: false,
      type: 'currency'
    }
  ],
  tradetrackerV1: [
    {
      key: 'customerId',
      label: 'Customer ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'passphrase',
      label: 'Passphrase',
      secret: true,
      type: 'password'
    },
    {
      key: 'locale',
      label: 'Locale',
      secret: true,
      type: 'tradeTrackerLocale'
    }
  ],
  ebayV1: [
    {
      key: 'accountSid',
      label: 'Read/write Account SID',
      secret: false,
      type: 'text'
    },
    {
      key: 'authToken',
      label: 'Read/write Auth Token',
      secret: true,
      type: 'text'
    }
  ],
  effiliationV1: [
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  everflowV1: [
    {
      key: 'advertiser',
      label: 'Advertiser Name',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  flexoffersV1: [
    {
      key: 'domainId',
      label: 'Domain ID',
      secret: true,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'text'
    }
  ],
  idevaffiliateV1: [
    {
      key: 'advertiser',
      label: 'Advertiser Name',
      secret: false,
      type: 'text'
    },
    {
      key: 'loginUrl',
      label: 'URL of Login Page',
      secret: false,
      type: 'url'
    },
    {
      key: 'username',
      label: 'Username',
      secret: true,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    }
  ],
  impactV1: [
    {
      key: 'accountSid',
      label: 'Read/write Account SID',
      secret: false,
      type: 'text'
    },
    {
      key: 'authToken',
      label: 'Read/write Auth Token',
      secret: true,
      type: 'text'
    }
  ],
  leaddynoV1: [
    {
      key: 'advertiserName',
      label: 'Advertiser Name',
      secret: false,
      type: 'text'
    },
    {
      key: 'advertiserUrl',
      label: 'Advertiser Website URL',
      secret: false,
      type: 'url'
    },
    {
      key: 'loginUrl',
      label: 'Affiliate Login URL',
      secret: false,
      type: 'url'
    },
    {
      key: 'username',
      label: 'Email',
      secret: true,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    },
    {
      key: 'affiliateCode',
      label: 'Affiliate Code',
      secret: false,
      type: 'text'
    },
    {
      key: 'currency',
      label: 'Currency',
      secret: false,
      type: 'currency'
    }
  ],
  linkconnectorV1: [
    {
      key: 'affiliateId',
      label: 'Affiliate ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'text'
    }
  ],
  cjV1: [
    {
      key: 'publisherId',
      label: 'Publisher ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiToken',
      label: 'API Token',
      secret: true,
      type: 'text'
    }
  ],
  awinV1: [
    {
      key: 'publisherId',
      label: 'Publisher ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'apiToken',
      label: 'API Token',
      secret: true,
      type: 'text'
    }
  ],
  avantlinkV1: [
    {
      key: 'affiliateId',
      label: 'Affiliate ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'authKey',
      label: 'Auth Key',
      secret: true,
      type: 'text'
    }
  ],
  refersionV1: [
    {
      key: 'apiToken',
      label: 'GraphQL Access Token',
      secret: true,
      type: 'text'
    }
  ],
  redVenturesV1: [
    {
      key: 'clientId',
      label: 'Client ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'clientSecret',
      label: 'Client Secret',
      secret: true,
      type: 'password'
    },
    {
      key: 'publisherId',
      label: 'Publisher ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'propertyId',
      label: 'Property ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'domain',
      label: 'Domain',
      secret: false,
      type: 'domain'
    }
  ],
  ltkV1: [
    {
      key: 'username',
      label: 'Username (Email)',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    }
  ],
  maxbountyV1: [
    {
      key: 'username',
      label: 'Username (Email)',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    }
  ],
  mvfV1: [
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'text'
    }
  ],
  shareasaleV1: [
    {
      key: 'affiliateId',
      label: 'Affiliate ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'token',
      label: 'Token',
      secret: true,
      type: 'text'
    },
    {
      key: 'secretKey',
      label: 'Secret Key',
      secret: true,
      type: 'text'
    }
  ],
  skimlinksV1: [
    {
      key: 'publisherId',
      label: 'Publisher ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'clientId',
      label: 'Client ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'clientSecret',
      label: 'Client Secret',
      secret: true,
      type: 'text'
    },
    {
      key: 'sites',
      label: 'Sites',
      secret: false,
      type: 'domainAndIdPairs'
    }
  ],
  pepperjamV1: [
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'text'
    },
    {
      key: 'websiteId',
      label: 'Publisher ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'currency',
      label: 'Account currency',
      secret: false,
      type: 'currency'
    }
  ],
  expressVpnV1: [
    {
      key: 'username',
      label: 'Username (Email)',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    }
  ],
  postAffiliateProV1: [
    {
      key: 'advertiser',
      label: 'Affiliate Program Name',
      secret: false,
      type: 'text'
    },
    {
      key: 'url',
      label: 'Affiiliate Login Domain',
      secret: false,
      type: 'text'
    },
    {
      key: 'username',
      label: 'Username (Email)',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    },
    {
      key: 'currency',
      label: 'Commission currency',
      secret: false,
      type: 'currency'
    }
  ],
  revoffersV1: [
    {
      key: 'apiKey',
      label: 'API Key',
      secret: true,
      type: 'password'
    }
  ],
  stackedBrandsV1: [
    {
      key: 'username',
      label: 'Username (Email)',
      secret: false,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    }
  ],
  webgainsV1: [
    {
      key: 'username',
      label: 'Username',
      secret: true,
      type: 'text'
    },
    {
      key: 'password',
      label: 'Password',
      secret: true,
      type: 'password'
    },
    {
      key: 'campaignId',
      label: 'Campaign ID',
      secret: false,
      type: 'text'
    },
    {
      key: 'domain',
      label: 'Domain name',
      secret: false,
      type: 'domain'
    },
    {
      key: 'country',
      label: 'Country',
      secret: false,
      type: 'webgainsCountry'
    }
  ]
};

export interface IReportingParams {
  spaceId: string;
  handler: string;
  start: number;
  end: number;
  checkPastTransactionsForUpdates?: boolean;
}

export interface IReportingPreviewParams {
  spaceId: string;
  handler: string;
  integrationId: string;
  start: number;
  end: number;
  checkPastTransactionsForUpdates?: boolean;
}

export interface IReportingTestParams {
  handler: string;
  secret: ISecret;
}

export type PayoutApiResult = {
  integrationId: string;
  response: any | null;
  payouts: Payout[];
};

export type ReportingApiResult = {
  url: string;
  response: any | null;
  integrationId: string;
  report: IReportPreview | null;
  errorType?: string;
  errorMsg?: string;
};

export interface IReportingCoverageItem {
  start: Timestamp;
  end: Timestamp;
}

export interface IReportingStats {
  spaceId: string;
  partnerKey: string;
  oldestPendingSaleDate: Timestamp | null;
  lastRun: {
    reportId: string;
    ts: Timestamp;
  } | null;
  coverage: IReportingCoverageItem[];
}

export const toReportingStatsId = (spaceId: string, partnerKey: string) =>
  [spaceId, partnerKey].join('-');

export const emptyReportingStats = (
  spaceId: string,
  partnerKey: string
): IReportingStats => ({
  spaceId,
  partnerKey,
  oldestPendingSaleDate: null,
  lastRun: null,
  coverage: []
});

export interface IReportingExtensionParams {
  spaceId: string;
  partnerKey: string;
  start: number;
  end: number;
  data: any;
  version: string;
}

export type TradeTrackerLocale = {
  label: string;
  value: string;
};

export const TRADE_TRACKER_LOCALES: TradeTrackerLocale[] = [
  {
    label: 'Argentina (es)',
    value: 'es_AR'
  },
  {
    label: 'Belgium (nl)',
    value: 'nl_BE'
  },
  {
    label: 'Belgium (fr)',
    value: 'fr_BE'
  },
  {
    label: 'Czech Republic (cs)',
    value: 'cs_CZ'
  },
  {
    label: 'Denmark (da)',
    value: 'da_DK'
  },
  {
    label: 'Germany (de)',
    value: 'de_DE'
  },
  {
    label: 'Estonia (et)',
    value: 'et_EE'
  },
  {
    label: 'United Kingdom (en)',
    value: 'en_GB'
  },
  {
    label: 'Spain (es)',
    value: 'es_ES'
  },
  {
    label: 'France (fr)',
    value: 'fr_FR'
  },
  {
    label: 'Italy (it)',
    value: 'it_IT'
  },
  {
    label: 'Hungary (hu)',
    value: 'hu_HU'
  },
  {
    label: 'Mexico (es)',
    value: 'es_MX'
  },
  {
    label: 'Netherlands (nl)',
    value: 'nl_NL'
  },
  {
    label: 'Norway (no)',
    value: 'nb_NO'
  },
  {
    label: 'Austria (de)',
    value: 'de_AT'
  },
  {
    label: 'Poland (pl)',
    value: 'pl_PL'
  },
  {
    label: 'Portugal (pt)',
    value: 'pt_PT'
  },
  {
    label: 'Finland (fi)',
    value: 'fi_FI'
  },
  {
    label: 'Sweden (sv)',
    value: 'sv_SE'
  },
  {
    label: 'Russia (ru)',
    value: 'ru_RU'
  },
  {
    label: 'Ukraine (uk)',
    value: 'uk_UA'
  }
];

export type WebgainsCountry = {
  id: string;
  label: string;
};

export const WEBGAINS_COUNTRIES: WebgainsCountry[] = [
  {
    id: 'US',
    label: 'United States'
  },
  {
    id: 'AU',
    label: 'Australia'
  },
  {
    id: 'DE',
    label: 'Germany'
  },
  {
    id: 'ES',
    label: 'Spain'
  },
  {
    id: 'FR',
    label: 'France'
  },
  {
    id: 'GB',
    label: 'United Kingdom'
  },
  {
    id: 'IE',
    label: 'Ireland'
  },
  {
    id: 'IT',
    label: 'Italy'
  },
  {
    id: 'NL',
    label: 'Netherlands'
  },
  {
    id: 'PL',
    label: 'Poland'
  },
  {
    id: 'PT',
    label: 'Portugal'
  },
  {
    id: 'SE',
    label: 'Sweden'
  }
];

export type RakutenNetwork = {
  id: number;
  label: string;
  currency: CurrencyCode;
};

export type AmazonMarketplace = {
  id: string;
  label: string;
};

export const AMAZON_MARKETPLACES: AmazonMarketplace[] = [
  { id: 'www.amazon.ae', label: 'Amazon.ae' },
  { id: 'www.amazon.ca', label: 'Amazon.ca' },
  { id: 'www.amazon.cn', label: 'Amazon.cn' },
  { id: 'www.amazon.co.jp', label: 'Amazon.co.jp' },
  { id: 'www.amazon.co.uk', label: 'Amazon.co.uk' },
  { id: 'www.amazon.com', label: 'Amazon.com' },
  { id: 'www.amazon.com.au', label: 'Amazon.com.au' },
  { id: 'www.amazon.com.br', label: 'Amazon.com.br' },
  { id: 'www.amazon.com.mx', label: 'Amazon.com.mx' },
  { id: 'www.amazon.de', label: 'Amazon.de' },
  { id: 'www.amazon.es', label: 'Amazon.es' },
  { id: 'www.amazon.fr', label: 'Amazon.fr' },
  { id: 'www.amazon.in', label: 'Amazon.in' },
  { id: 'www.amazon.it', label: 'Amazon.it' },
  { id: 'www.amazon.nl', label: 'Amazon.nl' },
  { id: 'www.amazon.pl', label: 'Amazon.pl' },
  { id: 'www.amazon.sa', label: 'Amazon.sa' },
  { id: 'www.amazon.se', label: 'Amazon.se' },
  { id: 'www.amazon.sg', label: 'Amazon.sg' }
];

export const RAKUTEN_NETWORKS: RakutenNetwork[] = [
  {
    id: 1,
    label: 'United States',
    currency: 'USD'
  },
  {
    id: 3,
    label: 'United Kingdom',
    currency: 'GBP'
  },
  {
    id: 5,
    label: 'Canada',
    currency: 'CAD'
  },
  {
    id: 7,
    label: 'France',
    currency: 'EUR'
  },
  {
    id: 8,
    label: 'Brazil',
    currency: 'BRL'
  },
  {
    id: 9,
    label: 'Germany',
    currency: 'EUR'
  },
  {
    id: 11,
    label: 'Japan',
    currency: 'JPY'
  },
  {
    id: 41,
    label: 'Australia',
    currency: 'AUD'
  }
];

export interface IReportingStatusUpdateParams {
  spaceId: string;
  integrationId: string;
  status: ISecret['status'];
}
