import { ISOString } from './etl';

export enum EmbeddingsProviderType {
  OPEN_AI = 'OPEN_AI'
}

export type EmpbeddingsRequest = {
  id: string;
  provider: EmbeddingsProviderType;
  status: 'PENDING' | 'COMPLETE' | 'ERROR' | 'LOADED';
  count: number;
};

export type ProductCatalogEmbeddingsProcess = {
  processId: string;
  retrievalProcessId: string | null;
  requestData: {
    bucket: string;
    path: string;
    catalogs: string[];
    count: number;
    provider: EmbeddingsProviderType;
  };

  step:
    | 'INIT'
    | 'REQUESTING'
    | 'PROCESSING'
    | 'LOADING'
    | 'COMPLETE'
    | 'ERROR'
    | 'ABORTED';

  requests: {
    [id: string]: EmpbeddingsRequest;
  };

  startedAt: ISOString;
  updatedAt: ISOString | null;
  finishedAt: ISOString | null;
  running: boolean;

  labels: string[];
};

export enum ProductFeedFormat {
  IMPACT_IR_TXT = 'IMPACT_IR_TXT',
  IMPACT_GOOGLE_XML = 'IMPACT_GOOGLE_XML', // we'll have to see what this looks like for other providers. the XML format might be fully standardized
  IMPACT_GOOGLE_TXT = 'IMPACT_GOOGLE_TXT',
  AMAZON_URLS = 'AMAZON_URLS'
}

export type ProductFeedFile = {
  path: string;
  format: ProductFeedFormat;
  catalogId: string; // our identifier for a given catalog
};

export type ProductFeedDownloadSourceFtp = {
  type: 'ftp';
  connectionDetails: { host: string; user: string; password: string };
  file: ProductFeedFile;
};

// This is only used in development, to operate on files we have on our local machines
export type ProductFeedDownloadSourceLocal = {
  type: 'local';
  file: ProductFeedFile;
};

export type ProductFeedDownloadSourceCs = {
  type: 'cs';
  bucket: string;
  file: ProductFeedFile;
};

export type ProductFeedDownloadSource =
  | ProductFeedDownloadSourceCs
  | ProductFeedDownloadSourceFtp
  | ProductFeedDownloadSourceLocal;

export type ProductFeedTransformSourceCs = {
  type: 'cs';
  file: ProductFeedFile;
  bucket: string;
};

export type ProductFeedTransformSourceLocal = {
  type: 'local';
  file: ProductFeedFile;
};

export type ProductFeedTransformSource =
  | ProductFeedTransformSourceCs
  | ProductFeedTransformSourceLocal;

export type ProductFeedLoadSourceCs = {
  type: 'cs';
  bucket: string;
  file: {
    catalogId: string;
    path: string;
    rows: number;
    v: 1; // allow to change how we implement the load process over time
  };
};

export type ProductFeedLoadSourceLocal = {
  type: 'local';
  file: {
    catalogId: string;
    path: string;
    rows: number;
    v: 1; // allow to change how we implement the load process over time
  };
};

export type ProductFeedLoadSource =
  | ProductFeedLoadSourceCs
  | ProductFeedLoadSourceLocal;

export type SourceWithStatus<T> = {
  id: string;
  source: T;
  status: 'PENDING' | 'IN_PROGRESS' | 'COMPLETE' | 'ERROR';
};

export type ProductCatalogRetrievalProcess = {
  processId: string;

  step:
    | 'INIT'
    | 'DOWNLOADING'
    | 'TRANSFORMING'
    | 'LOADING'
    | 'COMPLETE'
    | 'ERROR'
    | 'ABORTED';

  downloadSource: SourceWithStatus<ProductFeedDownloadSource>;

  transformSources: {
    [id: string]: SourceWithStatus<ProductFeedTransformSource>;
  };

  loadSources: {
    [id: string]: SourceWithStatus<ProductFeedLoadSource>;
  };

  startedAt: ISOString;
  updatedAt: ISOString | null;
  finishedAt: ISOString | null;
  running: boolean;

  labels: string[];
};

export type RetrievalProcessInitArgs = {
  processId: string | null;
  source: ProductFeedDownloadSource;
  labels?: string[];
  opts?: {
    startRemotely?: boolean;
  };
};

export type RetrievalProcessStartArgs = {
  processId: string;
};

export type RetrievalProcessTransformArgs = {
  processId: string;
  fileIds: string[];
};

export type RetrievalProcessLoadArgs = {
  processId: string;
  fileIds: string[];
};

export type RetrievalProcessAbortArgs = {
  processId: string;
};

export type EmbeddingsProcessStartArgs = {
  processId: string;
};

export type EmbeddingsProcessCheckArgs = {
  processId: string;
};

export type EmbeddingsProcessLoadArgs = {
  processId: string;
};

export type EmbeddingsProcessAbortArgs = {
  processId: string;
};

export type ProductCatalogEmbeddingsProcessSettings = {
  check: {
    autoRun: boolean;
    maxRequests: number;
    execute: boolean;
    // maxTokens - currently we're just limited by how many we ask for, as each request has so few tokens
  };
  load: {
    autoRun: boolean;
    maxParallel: number;
    chunksPerInvocation: number;
    execute: boolean;
  };
};

export const PRODUCT_CATALOG_EMBEDDINGS_PROCESS_SETTINGS_ID = 'default';

export type ProductCatalogRetrievalProcessSettings = {
  // TODO
};

export const PRODUCT_CATALOG_RETRIEVAL_PROCESS_SETTINGS_ID = 'default';
